define("ember-intl/-private/utils/hydrate", ["exports", "ember-intl/translations"], function (_exports, _translations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hydrate;
  /**
   * @private
   * @hide
   */
  function hydrate(service) {
    _translations.default.forEach(_ref => {
      let [locale, translations] = _ref;
      service.addTranslations(locale, translations);
    });
  }
});