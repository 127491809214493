define("ember-svg-jar/inlined/lib-tiktok", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.812 2.33c.19 1.522.716 2.72 1.569 3.55.83.808 1.995 1.286 3.48 1.374a.613.613 0 01.587.602v3.718a.61.61 0 01-.617.603 7.614 7.614 0 01-2.97-.316 12.159 12.159 0 01-1.988-.823v5.908c0 2.8-.96 5.04-2.415 6.613a8.353 8.353 0 01-3.815 2.366 7.964 7.964 0 01-4.372.037c-1.76-.492-3.39-1.624-4.547-3.454-.74-1.168-1.166-2.579-1.219-4.012-.046-1.322.213-2.674.83-3.894a7.644 7.644 0 012.97-3.138c1.341-.793 3.063-1.3 5.195-1.374a.619.619 0 01.648.58v3.94a.616.616 0 01-.572.602c-.297.051-.601.11-.906.176a8.335 8.335 0 00-.868.243c-.8.257-1.417.624-1.783 1.116-.358.478-.502 1.11-.38 1.933.16 1.11 1.028 1.947 2.056 2.285.426.14.876.199 1.31.155a2.586 2.586 0 001.203-.434c.93-.64 1.562-2.02 1.371-4.38V2.354c0-.331.282-.603.625-.603h3.983c.335 0 .602.25.625.566v.014zm.678 4.395c-.952-.926-1.57-2.19-1.843-3.748h-2.819V16.27c.221 2.829-.647 4.548-1.896 5.415a3.817 3.817 0 01-1.798.662 4.408 4.408 0 01-1.85-.213c-1.44-.478-2.65-1.668-2.887-3.263-.167-1.154.061-2.08.61-2.807.533-.713 1.363-1.212 2.406-1.55a12.481 12.481 0 011.493-.382v-2.778c-1.6.132-2.91.551-3.938 1.161-1.15.683-1.972 1.602-2.498 2.638a6.787 6.787 0 00-.708 3.321c.046 1.227.403 2.433 1.036 3.424.982 1.551 2.353 2.514 3.83 2.918a6.774 6.774 0 003.672-.037 7.114 7.114 0 003.237-2.013c1.264-1.367 2.094-3.33 2.094-5.813V10.01a.57.57 0 01.092-.308.635.635 0 01.86-.206c.945.544 1.813.963 2.666 1.22a6.617 6.617 0 001.957.294V8.415c-1.53-.191-2.764-.764-3.701-1.676l-.015-.014z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});