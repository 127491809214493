define("ember-svg-jar/inlined/exclamation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.186 4l-.438 13.436h-3.19L12.106 4h4.08zM12 21.596c0-.567.195-1.037.585-1.41.398-.38.921-.57 1.568-.57.656 0 1.178.19 1.568.57.39.373.585.843.585 1.41 0 .549-.195 1.014-.585 1.395-.39.381-.912.572-1.568.572-.647 0-1.17-.191-1.568-.572A1.877 1.877 0 0112 21.596z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});