define("ember-svg-jar/inlined/arrow-external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.1 20.719L18.544 5.275V15.77c0 .792.594 1.386 1.386 1.386.792 0 1.386-.594 1.386-1.386V1.909c0-.791-.594-1.385-1.386-1.385H6.07c-.396 0-.792.198-.99.396-.297.297-.396.594-.396.99 0 .792.594 1.385 1.386 1.385h10.494L1.12 18.74c-.594.594-.594 1.386 0 1.98.594.594 1.485.495 1.98 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});