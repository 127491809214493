define("ember-svg-jar/inlined/minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.85 12.65h24.3c.81 0 1.35.54 1.35 1.35s-.54 1.35-1.35 1.35H1.85C1.04 15.35.5 14.81.5 14s.54-1.35 1.35-1.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});