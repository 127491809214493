define("ember-svg-jar/inlined/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.087 3.952L2.89 25.03h22.394L14.087 3.952zm1.373-2.317l12.437 23.41a1.555 1.555 0 01-1.374 2.284H1.651a1.555 1.555 0 01-1.373-2.285l12.436-23.41a1.555 1.555 0 012.746 0zm-.001 8.309v7.652a1.371 1.371 0 11-2.743 0V9.944a1.371 1.371 0 012.743 0zm-1.372 10.579c.849 0 1.555.697 1.555 1.574 0 .876-.706 1.564-1.555 1.564a1.553 1.553 0 01-1.555-1.564 1.56 1.56 0 011.555-1.574z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});