define("ember-svg-jar/inlined/lib-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.147 5.25h21.706a1.41 1.41 0 011.397 1.407v14.918c.002.284-.083.563-.244.798a.524.524 0 01-.263.291c-.252.213-.57.33-.9.33H3.147a1.41 1.41 0 01-1.397-1.397V6.657A1.41 1.41 0 013.147 5.25zm-.06 15.732l7.34-7.627-7.34-6.201v13.828zm8.445-6.675L3.69 21.902h20.553l-7.493-7.595-2.195 1.838a.539.539 0 01-.689 0l-2.334-1.838zm6.305-.966l7.076 7.703V7.086l-7.076 6.255zM3.777 6.33l10.413 8.438 9.953-8.438H3.777z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});