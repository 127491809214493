define("ember-svg-jar/inlined/lib-pinterest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.765 1.75C8.717 1.75 3.5 5.16 3.5 10.867c0 2.378.742 4.222 2.092 5.194.396.284.889.236 1.32-.126.714-.598 1.542-2.465 1.13-3.32a3.153 3.153 0 01-.335-1.43c0-2.3 1.587-5.535 5.118-5.535 1.666 0 3.316.641 4.308 1.675.712.742 1.054 1.62 1.015 2.614-.135 3.38-2.003 6.255-3.465 6.232-.449-.007-.795-.16-1.06-.472-.351-.417-.443-1.004-.422-1.148.13-.548.312-1.127.483-1.69.349-1.119.674-2.178.674-3.099 0-1.122 0-3.001-2.27-3.068-.85-.016-1.62.294-2.267.92-.868.842-1.363 2.13-1.363 3.535 0 .568.074 1.13.218 1.67a1.8 1.8 0 01.025.896l-1.446 6.129c-.424 1.79-.265 3.851-.06 5.26.07.527.448.958.963 1.098.115.032.231.048.35.048.413-.002.793-.188 1.082-.56.731-.934 1.347-3.168 1.842-4.964l.041-.154c.065-.238.234-.893.408-1.576.64.495 1.528 1.013 3.04 1.098 1.875.107 3.616-.544 5.03-1.88 1.93-1.823 3.036-4.744 3.036-8.01 0-2.185-.935-4.337-2.564-5.902-1.716-1.645-4.08-2.552-6.658-2.552zm.002 1.17c2.273 0 4.346.794 5.845 2.228 1.4 1.347 2.202 3.19 2.202 5.057 0 2.944-.974 5.553-2.67 7.157-1.173 1.11-2.618 1.643-4.158 1.56-1.49-.082-2.142-.636-2.777-1.172a9.749 9.749 0 00-.303-.253.586.586 0 00-.934.314c-.23.9-.537 2.11-.63 2.452l-.042.151c-.442 1.604-1.047 3.804-1.668 4.601-.032.037-.082.06-.17.055-.056-.016-.095-.062-.105-.128-.158-1.077-.362-3.135.04-4.83l1.447-6.125a3.02 3.02 0 00-.036-1.47 5.22 5.22 0 01-.179-1.368c0-1.07.378-2.079 1.01-2.693.28-.27.738-.588 1.36-.588h.054c.891.027 1.132.265 1.132 1.894 0 .745-.303 1.719-.62 2.752-.182.58-.367 1.182-.505 1.769-.117.488.082 1.427.603 2.092.485.623 1.18.96 2.004.969h.033c2.476 0 4.479-3.872 4.619-7.357.052-1.308-.41-2.509-1.338-3.476-1.226-1.274-3.153-2.034-5.156-2.034-3.09 0-6.291 2.508-6.291 6.708 0 .683.142 1.297.442 1.916.098.378-.442 1.64-.8 1.931-.958-.763-1.503-2.27-1.503-4.168 0-4.971 4.621-7.943 9.094-7.943z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});