define("ember-svg-jar/inlined/gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M25.2 23.8V5.88c1.68.56 2.8 2.1 2.8 3.92v14c0 2.38-1.82 4.2-4.2 4.2h-14c-1.82 0-3.36-1.12-3.92-2.8H23.8c.84 0 1.4-.56 1.4-1.4zM4.2 0h14c2.38 0 4.2 1.82 4.2 4.2v14c0 2.38-1.82 4.2-4.2 4.2h-14c-2.38 0-4.2-1.82-4.2-4.2v-14C0 1.82 1.82 0 4.2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});