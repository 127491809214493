define("ember-svg-jar/inlined/nav-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M19.637 1.487c.65.65.65 1.625 0 2.276L9.4 14l10.237 10.237c.65.65.65 1.625 0 2.276-.65.65-1.625.65-2.274 0L5.988 15.138c-.65-.65-.65-1.625 0-2.276L17.363 1.487A1.755 1.755 0 0118.5 1c.325 0 .813.162 1.137.487z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});