define("ember-svg-jar/inlined/nav-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M1.3 8.175c.65-.65 1.626-.65 2.276 0l10.237 10.238L24.051 8.175c.65-.65 1.625-.65 2.275 0 .65.65.65 1.625 0 2.275L14.95 21.825c-.65.65-1.625.65-2.275 0L1.3 10.45a1.755 1.755 0 01-.488-1.137c0-.325.163-.813.488-1.138z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});