define("@ember/-internals/views/lib/views/states/destroying", ["exports", "@ember/-internals/views/lib/views/states/default"], function (_exports, _default3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var destroying = Object.assign(Object.assign({}, _default3.default), {
    appendChild() {
      throw new Error("You can't call appendChild on a view being destroyed");
    },
    rerender() {
      throw new Error("You can't call rerender on a view being destroyed");
    }
  });
  var _default2 = Object.freeze(destroying);
  _exports.default = _default2;
});