define("ember-svg-jar/inlined/lib-googlemybusiness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.63 3.5c-.957 0-1.8.642-2.054 1.564l-1.826 6.62v.071c0 .977.41 1.86 1.065 2.49v10.027c0 .876.722 1.598 1.598 1.598h19.174c.876 0 1.598-.722 1.598-1.598V14.246a3.45 3.45 0 001.065-2.49v-.072l-1.827-6.62A2.134 2.134 0 0022.37 3.5H5.63zm0 1.065h2.966l-.882 6.391H3.056l1.546-5.609a1.061 1.061 0 011.027-.782zm4.04 0h3.797v6.391H8.79l.881-6.39zm4.863 0h3.796l.882 6.391h-4.678v-6.39zm4.871 0h2.966c.482 0 .898.318 1.027.782l1.548 5.61h-4.659l-.882-6.392zM2.85 12.022h4.732a2.379 2.379 0 01-2.37 2.13c-1.238 0-2.23-.93-2.362-2.13zm5.877 0h4.687c-.137 1.197-1.108 2.13-2.343 2.13-1.236 0-2.207-.933-2.344-2.13zm5.859 0h4.687c-.137 1.197-1.108 2.13-2.344 2.13-1.235 0-2.206-.933-2.343-2.13zm5.832 0h4.733a2.373 2.373 0 01-2.363 2.13 2.379 2.379 0 01-2.37-2.13zM14 13.485c.605 1.016 1.667 1.732 2.93 1.732 1.24 0 2.312-.666 2.911-1.657a3.463 3.463 0 002.947 1.657c.471 0 .921-.095 1.332-.267v9.322a.524.524 0 01-.533.532H4.413a.524.524 0 01-.533-.532V14.95c.41.172.86.267 1.332.267a3.463 3.463 0 002.947-1.657 3.395 3.395 0 002.912 1.657c1.262 0 2.324-.716 2.929-1.732zm5.592 3.33a3.466 3.466 0 00-3.462 3.462 3.466 3.466 0 003.462 3.462 3.466 3.466 0 003.462-3.462c0-.09-.007-.178-.013-.266h-3.182v1.065h1.983a2.394 2.394 0 01-2.25 1.598 2.4 2.4 0 01-2.396-2.397 2.4 2.4 0 012.396-2.397 2.39 2.39 0 011.935.995l.763-.763a3.457 3.457 0 00-2.698-1.297z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});