define("ember-svg-jar/inlined/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-opacity=\".873\" fill-rule=\"evenodd\" d=\"M2.852 2.675c4.29-.548 8.64-.762 13.072-.642l6.166.322c.658.07 1.67.177 3.056.32.805.1 1.498.464 2.036 1.07.54.607.818 1.338.818 2.148v15.084c0 .74-.232 1.42-.685 2.006-.46.598-1.08.99-1.82 1.159A50.876 50.876 0 0114 25.474c-3.821 0-7.653-.444-11.49-1.331a3.131 3.131 0 01-1.825-1.16A3.225 3.225 0 010 20.977V5.893c0-.81.278-1.541.818-2.148a3.193 3.193 0 012.034-1.07zm12.993 1.356a82.743 82.743 0 00-12.743.629c-.325.04-.574.171-.79.414-.214.24-.312.499-.312.819v15.084c0 .297.086.547.269.785.175.227.39.364.687.431A48.877 48.877 0 0014 23.474c3.668 0 7.349-.426 11.047-1.282.293-.066.51-.203.684-.43.183-.238.269-.488.269-.785V5.893c0-.32-.098-.578-.313-.82a1.184 1.184 0 00-.768-.41c-1.367-.142-2.38-.248-2.987-.315zM10.72 7.5c.125 0 .255.026.39.078a32.49 32.49 0 012.852 1.344c.963.51 1.784.982 2.46 1.414.678.432 1.319.865 1.923 1.297.604.432 1.036.75 1.297.953.26.203.432.346.515.43.23.177.344.421.344.734a.949.949 0 01-.344.75c-2.948 2.365-5.963 4.172-9.047 5.422a1.087 1.087 0 01-.39.078.983.983 0 01-.516-.14c-.292-.178-.448-.428-.469-.75-.01-.084-.033-.352-.07-.805a55.836 55.836 0 01-.11-2.008A61.986 61.986 0 019.5 13.75c0-.813.018-1.659.055-2.54.036-.88.073-1.551.11-2.015.036-.463.059-.726.07-.789.02-.323.176-.578.468-.765a.983.983 0 01.516-.141z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});