define("ember-svg-jar/inlined/double-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24.253 15.653a1.224 1.224 0 01-1.773 0l-7.98-7.98-7.98 7.98a1.224 1.224 0 01-1.773 0 1.224 1.224 0 010-1.773l8.866-8.867a1.225 1.225 0 011.774 0l8.866 8.867c.254.253.38.633.38.887 0 .38-.126.633-.38.886zm.38 6.714c0-.38-.126-.634-.38-.887l-8.866-8.867a1.224 1.224 0 00-1.774 0L4.747 21.48a1.224 1.224 0 000 1.773 1.224 1.224 0 001.773 0l7.98-7.98 7.98 7.98a1.224 1.224 0 001.773 0c.254-.253.38-.506.38-.886z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});