define("ember-svg-jar/inlined/nav-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.488 26.513c-.65-.65-.65-1.625 0-2.275L18.726 14 8.488 3.763c-.65-.65-.65-1.625 0-2.275.65-.65 1.625-.65 2.275 0l11.375 11.375c.65.65.65 1.625 0 2.275L10.763 26.513A1.755 1.755 0 019.626 27c-.325 0-.813-.162-1.138-.487z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});