define("ember-svg-jar/inlined/lib-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14.646 13.512h.002l11.613 4.083c1.015.354.967 1.803-.036 2.12l-5.25 1.644-.003.001-1.617 5.331v.002c-.313 1.018-1.74 1.066-2.087.037v-.001l-4.02-11.795v-.001a1.131 1.131 0 01.26-1.157 1.094 1.094 0 011.138-.264zm-.88 1.237l.519-.182 4.02 11.797v-.002l1.618-5.332c.051-.178.146-.34.276-.47a1.06 1.06 0 01.461-.27l5.24-1.64.001-.001-11.617-4.084-.518.184zM5.964 26.143a.426.426 0 01-.122-.584l3.17-4.959a.412.412 0 01.576-.124.426.426 0 01.122.584l-3.17 4.96a.412.412 0 01-.576.123zm-4.95-14.97a.415.415 0 01.51-.298l5.623 1.53c.222.06.354.292.294.517a.415.415 0 01-.51.299l-5.623-1.53a.423.423 0 01-.294-.518zm25.026-1.047a.424.424 0 01-.24.546l-5.435 2.118a.414.414 0 01-.537-.242.424.424 0 01.24-.546l5.434-2.12a.414.414 0 01.538.244zM12.966 1a.418.418 0 01.437.4l.304 5.905a.42.42 0 01-.393.444.418.418 0 01-.437-.4l-.305-5.905a.42.42 0 01.394-.443zm4.644 7.96a.426.426 0 01-.114-.587l1.85-2.808a.412.412 0 01.577-.116c.19.13.242.392.114.585l-1.85 2.809a.412.412 0 01-.576.116zm2.976-4.585a.426.426 0 01-.114-.586l.231-.35a.412.412 0 01.577-.117c.19.13.242.392.114.586l-.23.35a.412.412 0 01-.578.117zM9.08 9.804a.426.426 0 00.114-.586L7.344 6.41a.412.412 0 00-.576-.117.426.426 0 00-.114.586l1.849 2.809a.412.412 0 00.577.116zM6.104 5.22a.426.426 0 00.115-.586l-.231-.351a.412.412 0 00-.577-.116.426.426 0 00-.115.585l.232.352a.412.412 0 00.576.116zm1.511 11.902a.415.415 0 00-.515-.29l-3.198.93a.423.423 0 00-.285.523.415.415 0 00.514.29l3.198-.93a.423.423 0 00.286-.523zm-5.208 1.476a.415.415 0 00-.514-.29l-.4.116a.423.423 0 00-.285.523.415.415 0 00.514.29l.4-.117a.423.423 0 00.285-.522z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});