define("ember-useragent/instance-initializers/user-agent", ["exports", "@ember/object", "@ember/debug"], function (_exports, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /* global FastBoot */

  function getUserAgent(appInstance) {
    if (typeof FastBoot === 'undefined') {
      const userAgent = (0, _object.get)(window, 'navigator.userAgent');
      (false && !(userAgent) && (0, _debug.assert)('No `userAgent` present in window.navigator', userAgent));
      return userAgent;
    } else {
      const fastboot = appInstance.lookup('service:fastboot');
      const headers = (0, _object.get)(fastboot, 'request.headers');
      const userAgent = headers.get('user-agent');
      (false && !(userAgent) && (0, _debug.assert)('No `user-agent` present in FastBoot headers.', userAgent));
      return userAgent;
    }
  }
  function initialize(appInstance) {
    const service = appInstance.lookup('service:user-agent');
    (0, _object.set)(service, 'userAgent', getUserAgent(appInstance));
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});