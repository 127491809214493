define("ember-svg-jar/inlined/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-opacity=\".873\" fill-rule=\"evenodd\" d=\"M12.301 22.51c.412-.412.678-.904.798-1.476s.083-1.12-.111-1.647l2.041-2.024c.584.915.875 1.927.875 3.037 0 1.567-.554 2.916-1.664 4.049l-1.904 1.887C11.203 27.446 9.854 28 8.286 28c-1.566 0-2.922-.56-4.065-1.681l-2.54-2.54C.561 22.67 0 21.323 0 19.74s.56-2.937 1.681-4.058l1.888-1.887C4.7 12.684 6.05 12.13 7.618 12.13c1.12 0 2.144.297 3.07.892l-2.024 2.025a3.116 3.116 0 00-1.046-.19c-.835 0-1.544.293-2.128.876L3.603 17.62a2.886 2.886 0 00-.875 2.119c0 .83.292 1.536.875 2.119l2.556 2.54a2.896 2.896 0 002.128.874c.835 0 1.544-.292 2.127-.875l1.887-1.887zM26.32 4.22C27.439 5.33 28 6.678 28 8.262c0 1.584-.56 2.937-1.681 4.058l-1.87 1.87c-1.121 1.12-2.471 1.681-4.05 1.681a5.534 5.534 0 01-3.036-.875l2.024-2.024c.366.114.704.171 1.013.171.823 0 1.526-.292 2.11-.875l1.887-1.887c.583-.583.875-1.29.875-2.119 0-.83-.292-1.536-.875-2.119l-2.54-2.54a2.896 2.896 0 00-2.127-.874c-.846 0-1.55.292-2.11.875L15.733 5.49c-.583.56-.875 1.264-.875 2.11 0 .344.063.687.189 1.03l-2.025 2.024A5.485 5.485 0 0112.13 7.6c0-1.567.555-2.916 1.664-4.049l1.887-1.887C16.814.554 18.163 0 19.731 0c1.566 0 2.916.555 4.048 1.664l2.54 2.557zM8.2 17.86l9.505-9.505 1.939 1.94-9.505 9.504L8.2 17.86z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});