define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M11.883 18.579L5.15 10.913c-.725-.725-1.812-.725-2.537 0-.725.725-.725 1.812 0 2.537l8.002 8.934c.724.724 1.812.724 2.536 0L25.968 7.08c.725-.724.725-1.812 0-2.536-.724-.725-1.993-.725-2.536 0L11.883 18.579z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});