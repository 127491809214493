define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "apps": {
      "email_collection": {
        "close": "Close",
        "contact_collection": {
          "errors": {
            "duplicate_submission": "This email has already been submitted.",
            "network_fail": "Something went wrong. Please try again."
          }
        },
        "contact_exists_error": "The email address is already registered.",
        "email": "Email",
        "email_error": "Enter a valid email.",
        "email_placeholder": "name@email.com",
        "generic_error": "Something went wrong. Please try again.",
        "success": "Thanks for signing up!"
      }
    },
    "aria": {
      "branded_footer": {
        "close": "Close banner",
        "open": "Learn more"
      },
      "modal": {
        "close": "close modal",
        "close_mobile": "close modal on mobile"
      }
    },
    "branded_footer": {
      "lg": {
        "description": "Powered by Later.com",
        "title": "Create a Linkin.bio Page"
      },
      "username": {
        "url": "linkin.bio/"
      }
    },
    "close": "Go Back",
    "error": {
      "503": {
        "description": "Please check back shortly. For more information, check out '<a href=\"https://twitter.com/latermedia\" target=\"_blank\">'Twitter status.'</a>'",
        "title": "We're currently undergoing maintenance."
      },
      "embed": {
        "go_to_instagram": {
          "button": "Go to Instagram",
          "description": "Go to {username}'s Instagram profile instead"
        },
        "go_to_lib": {
          "button": "Go to Linkin.bio",
          "description": "Go to {username}'s Linkin.bio instead"
        },
        "title": "No posts to show"
      },
      "failed_request": {
        "description": "'<p class=\"u--m__t__lg u--m__b__0\">'Please check your internet connection and try loading the page again.'</p>'",
        "title": "Could not connect"
      },
      "generic": {
        "description": "'<p class=\"u--m__t__lg u--m__b__0\">'Please try reloading the page.'</p>'",
        "reload_button": "Reload Page",
        "title": "Something went wrong"
      }
    },
    "featured_media": {
      "youtube": "Youtube video player"
    },
    "feed": {
      "tabs": {
        "instagram": "Instagram",
        "tiktok": "TikTok"
      }
    },
    "landing_text": "Is available on premium and business plans of later.com",
    "loading": "Loading...",
    "multi_links": {
      "heading": "Explore Post Links",
      "view_button": "View"
    },
    "navbar": {
      "avatar_alt": "{username}'s avatar",
      "username": "@{username}"
    },
    "post": {
      "description": "See {profileName} photos and products from Instagram about {postText}",
      "title": "{postText}: Photos and Products from {profileName}"
    },
    "profile": {
      "instagram": {
        "description": "Check out {name}'s Instagram posts from link in bio. Discover all our products, photos, and posts from Instagram.",
        "title": "{name}'s Instagram Posts and Link in Bio"
      },
      "no_posts": {
        "embed": "No posts to show",
        "remaining": "No more posts to show"
      },
      "tiktok": {
        "description": "Check out our TikTok posts from link in bio. Discover all our products and posts from TikTok. ",
        "heading": "Linkin.bio for TikTok",
        "title": "TikTok Posts and Link in Bio"
      }
    },
    "report_banner": {
      "button": "Report this page",
      "cancel_button": "Cancel",
      "cta": "Report Page",
      "description": "If this page violates our '<a class=\"u--text--underline\" href=\"https://later.com/terms/\">'Terms of Service'</a>', please report it and fill out our form. We’ll review your report and take action if needed.",
      "title": "Report This Page?"
    },
    "seo": {
      "default": {
        "description": "Check out {name}'s link in bio for their latest posts and shared picks.",
        "title": "{name}'s Link in Bio"
      }
    },
    "title": "Linkin.bio"
  }]];
  _exports.default = _default;
});