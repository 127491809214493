define("ember-intl/-private/utils/flatten", ["exports", "ember-intl/-private/utils/empty-object"], function (_exports, _emptyObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = flatten;
  const hasOwnProperty = Object.prototype.hasOwnProperty;
  /**
   * @private
   * @hide
   */
  function flatten(src) {
    const result = new _emptyObject.default();
    for (const key in src) {
      if (!hasOwnProperty.call(src, key)) {
        continue;
      }
      const value = src[key];
      if (typeof value === 'object' && value) {
        const hash = flatten(value);
        for (const suffix in hash) {
          result["".concat(key, ".").concat(suffix)] = hash[suffix];
        }
      } else {
        result[key] = value;
      }
    }
    return result;
  }
});