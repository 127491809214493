define("ember-svg-jar/inlined/lib-youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.917 5.25c-3.753 0-7.277.284-9.154.682-1.25.284-2.331 1.137-2.558 2.445-.226 1.363-.455 3.41-.455 5.969 0 2.558.226 4.547.51 5.969.23 1.25 1.309 2.16 2.559 2.444 1.992.398 5.4.682 9.153.682s7.162-.284 9.151-.681c1.253-.285 2.332-1.137 2.558-2.445.23-1.422.514-3.467.569-6.025 0-2.558-.284-4.605-.569-6.027-.226-1.25-1.305-2.16-2.558-2.445-1.99-.284-5.456-.568-9.206-.568zm0 1.137c4.092 0 7.388.34 8.98.624.853.229 1.534.797 1.648 1.537.341 1.818.568 3.752.568 5.742-.055 2.443-.34 4.432-.568 5.854-.17 1.079-1.306 1.42-1.648 1.536-2.047.398-5.458.68-8.87.68-3.41 0-6.876-.227-8.866-.68-.853-.228-1.535-.797-1.648-1.536-.455-1.592-.626-3.695-.626-5.798 0-2.616.229-4.548.455-5.74.171-1.082 1.364-1.424 1.648-1.537 1.876-.398 5.345-.682 8.927-.682zm-3.071 3.41v9.096l7.958-4.547-7.958-4.548zm1.137 1.935l4.547 2.614-4.547 2.613v-5.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});