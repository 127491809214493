define("ember-svg-jar/inlined/verified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M-5.351 3.435a1.54 1.54 0 01-.004 2.188l-8.419 8.418 8.419 8.42c.571.571.608 1.473.1 2.083l-.096.104a1.54 1.54 0 01-2.188-.004l-8.419-8.419-8.419 8.42a1.544 1.544 0 01-2.083.099l-.105-.096a1.54 1.54 0 01.004-2.188l8.419-8.42-8.419-8.417a1.544 1.544 0 01-.1-2.084l.096-.104a1.54 1.54 0 012.188.004l8.419 8.418 8.419-8.418a1.544 1.544 0 012.083-.1l.105.096zM14 .5C6.514.5.5 6.514.5 14S6.514 27.5 14 27.5 27.5 21.486 27.5 14 21.486.5 14 .5zm0 24.545A11 11 0 012.955 14 11 11 0 0114 2.955 11 11 0 0125.045 14 11 11 0 0114 25.045zm-.982-9.063l-2.3-2.577a1.186 1.186 0 00-1.718 0c-.49.49-.49 1.227 0 1.718l3.16 3.436c.49.491 1.226.491 1.717 0l5.841-6.84c.491-.492.491-1.228 0-1.719-.49-.49-1.35-.49-1.718 0l-4.982 5.982z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  };
  _exports.default = _default;
});